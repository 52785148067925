import { FC, PropsWithChildren } from 'react';

import { ButtonClosePreview } from './ButtonClosePreview';
import { HeaderPreview } from './HeaderPreview';
import { Toolbar } from './Toolbar';

type ReaderPreviewProps = PropsWithChildren<{
    readonly title: string;
}>;

export const ReaderPreview: FC<ReaderPreviewProps> = ({ title, children }) => {
    return (
        <div className="page reader">
            <header>
                <HeaderPreview title={title} />
            </header>
            <div className="viewport epub-reader">
                <div className="content">{children}</div>
            </div>
            <div className="reader-footer">
                <Toolbar primaryRowLeft={<ButtonClosePreview />} progressBar={null} />
            </div>
        </div>
    );
};
