import classNames from 'classnames';
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';

import { TileCover } from './TileCover';
import { ShortProduct } from '../../domain/product';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { isCSR } from '../../utils/nextjs/ssr';

const ProductTileContext = createContext<ShortProduct | undefined>(undefined);

export function useProductTileContext() {
    return useContext(ProductTileContext);
}

type ProductTileContentProps = PropsWithChildren<{
    readonly product: ShortProduct;
    readonly showMoreButton?: boolean;
}>;

// Ticket: https://borrowbox.atlassian.net/browse/PP-3109
const IS_SAFARI_AND_VERSION_IS_14_OR_LESS =
    isCSR() &&
    (function () {
        const { browser } = UAParser(window.navigator.userAgent);
        const isSafari = browser.name?.includes('Safari');
        const needFix = browser.version ? parseInt(browser.version) <= 14 : false;
        return isSafari && needFix;
    })();

export const ProductTileContent: FC<ProductTileContentProps> = ({ children, product, showMoreButton }) => {
    const {
        title,
        formatSpecifics: { loanFormat }
    } = product;

    // The data tag is a gild state field. It introduces a html/css rendering toggle.
    // In our component code we toggle the rendering of the preview via react state.
    // So we do not need this data attribute toggle and can hardcode it to true.
    const hasPreviewTag = true;

    const [productTileClassnames, setProductTileClassnames] = useState('product-tile');

    useEffect(() => {
        if (IS_SAFARI_AND_VERSION_IS_14_OR_LESS) {
            return setProductTileClassnames(
                classNames('product-tile-v2', {
                    eaudiobook: loanFormat === LoanFormatType.eaudiobooks,
                    ebook: loanFormat === LoanFormatType.ebooks,
                    emagazine: [LoanFormatType.eMagazines, LoanFormatType.eMagazineIssues].includes(loanFormat)
                })
            );
        }
    }, [loanFormat]);

    return (
        <ProductTileContext.Provider value={product}>
            <div className="item-wrapper" aria-label={title}>
                <div className={productTileClassnames} data-haspreview={hasPreviewTag} data-testid="product-tile">
                    <TileCover showMoreButton={showMoreButton} />
                    {children}
                </div>
            </div>
        </ProductTileContext.Provider>
    );
};
