import { GetServerSideProps, NextPage } from 'next';
import { useEffect } from 'react';

import EPubReaderWrapper from './EPubReaderWrapper';
import PdfReaderWrapper from './PdfReaderWrapper';
import { loadManifest } from '../../../api/mdp/loadManifest';
import { fetchProductDetails } from '../../../api/product-details/apiFetchProductDetails';
import { TextFileFormat } from '../../../domain/fileFormat';
import { isFeatureEnabled } from '../../../hooks/getters/useFeatureToggle';
import { EPubInfo } from '../../../page-components/reader/epub/components/EpubReader';
import { Manifest } from '../../../page-components/reader/epub/lib/types';
import { PdfInfo } from '../../../page-components/reader/pdf/components/PdfReader';
import { initSSR } from '../../../setup/ssr';
import { LoanFormatType } from '../../../utils/domain/loanFormat';
import LoggingUtils from '../../../utils/logging/LoggingUtils';
import { buildPdfInfo } from '../../read/[productId]';

const PSPDFKIT_URL_ENV = process.env['PSPDFKIT_URL'] ?? '';

export const PREVIEW_ERROR = 'preview error';

type ReaderPageProps =
    | {
          readonly error: true;
          readonly info: undefined;
          readonly manifest?: never;
      }
    | {
          readonly error: false;
          readonly info: EPubInfo;
          readonly manifest: Manifest;
      }
    | {
          readonly error: false;
          readonly info: PdfInfo;
          readonly manifest?: Manifest;
      };

const ReaderPage: NextPage<ReaderPageProps> = ({ error, info, manifest }) => {
    useEffect(() => {
        if (error) postErrorMessage();
    }, [error]);

    if (error) return null;

    switch (info.format) {
        case TextFileFormat.epub:
            return <EPubReaderWrapper productInfo={info} manifest={manifest!} preview={true} />;
        case TextFileFormat.pdf:
            return <PdfReaderWrapper pdfInfo={info} preview={true} />;
        default:
            return null;
    }
};

export const getServerSideProps: GetServerSideProps<ReaderPageProps> = async context => {
    const { productId } = context.query;

    if (typeof productId !== 'string') {
        LoggingUtils.logSSRError(`failed to render preview reader, invalid product ID: ${productId}`);
        return { props: { error: true } };
    }

    try {
        const { ssrClient, siteParams } = await initSSR(context);
        const productDetails = await fetchProductDetails(ssrClient, { productId, ...siteParams });

        const { title, authors, format, formatSpecifics, coverUrl, rootProduct } = productDetails;
        const { loanFormat } = formatSpecifics;
        const isMagazineIssue = loanFormat === LoanFormatType.eMagazineIssues;
        const isEbook = loanFormat === LoanFormatType.ebooks;

        const getManifest = () =>
            loadManifest({
                ssrClient,
                productId,
                siteParams,
                preview: true
            });

        if (format === TextFileFormat.pdf && (isMagazineIssue || isEbook)) {
            if (!PSPDFKIT_URL_ENV) LoggingUtils.logSSRError('missing preview PSPDFKIT_URL environment variable');

            // TODO: enable reflow for preview if supported by backend
            const isReflow = false; /* || layout === 'REFLOWABLE'*/

            const manifestData =
                isFeatureEnabled('epressReflow', siteParams) && isReflow ? { manifest: await getManifest() } : {};

            const info = buildPdfInfo({
                ...productDetails,
                format,
                pspdfKitUrl: PSPDFKIT_URL_ENV,
                // TODO: replace `hasCoverPage` with fetched productDetails data, as soon as API returns it
                hasCoverPage: true,
                loanFormat,
                rootProduct
            });
            return { props: { info, ...manifestData, error: false } };
        } else if (format === TextFileFormat.epub) {
            return {
                props: {
                    info: { format, productId, title, authors, coverUrl },
                    manifest: await getManifest(),
                    error: false
                }
            };
        } else {
            LoggingUtils.logSSRError(`Failed to fetch preview ProductDetails for product ID: ${productId}`);
            return { props: { error: true } };
        }
    } catch (error) {
        LoggingUtils.logSSRError('failed to render preview route', error);
        return { props: { error: true } };
    }
};

export const postErrorMessage = () => window.top?.postMessage(PREVIEW_ERROR, '*');

export default ReaderPage;
