import Link from 'next/link';
import { FC } from 'react';

import { useProductTileContext } from './ProductTileContent';
import { ProductTilePreview } from './ProductTilePreview';
import { SIZES_PRODUCT_TILE_COVER } from '../../../next-image-sizes';
import { getFullTitle } from '../../domain/product';
import { pathProductDetails } from '../../utils/routes/paths';
import { getPlaceholderCoverByFormat } from '../../utils/styles/image';
import { NextImage } from '../next/NextImage';

type IProductTileCoverProps = {
    readonly showMoreButton?: boolean;
};

export const TileCover: FC<IProductTileCoverProps> = ({ showMoreButton = false }) => {
    const product = useProductTileContext();

    if (!product) return null;

    const { coverUrl } = product;
    const title = getFullTitle(product);

    const placeholderCover = getPlaceholderCoverByFormat(product.formatSpecifics.loanFormat);

    return (
        <div className="cover">
            <Link href={pathProductDetails(product)} legacyBehavior>
                <a>
                    <NextImage
                        src={coverUrl}
                        sizes={SIZES_PRODUCT_TILE_COVER}
                        alt={title}
                        placeholder="blur"
                        blurDataURL={placeholderCover}
                    />
                </a>
            </Link>
            <ProductTilePreview product={product} showMoreButton={showMoreButton} />
        </div>
    );
};
