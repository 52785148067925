import dynamic from 'next/dynamic';
import Script from 'next/script';
import { useState } from 'react';

import { useReaderToken } from '../../../api/reader/useReaderToken';
import { BasicLayout } from '../../../components/layout/BasicLayout';
import { NotFound, NotFoundScope } from '../../../page-components/NotFound';
import { PdfInfo } from '../../../page-components/reader/pdf/components/PdfReader';
import { ReflowOverlays } from '../../../page-components/reader/pdf/utils/reflowOverlays';
import { ReaderProgress } from '../../../page-components/reader/shared/types/progress';

type PdfReaderWrapperProps = {
    readonly pdfInfo: PdfInfo;
    readonly progress?: ReaderProgress;
    readonly preview?: boolean;
    readonly reflowOverlays?: ReflowOverlays;
};

const PdfReaderWrapper = ({
    pdfInfo,
    progress = { majorPosition: '0', minorPosition: 0 },
    preview = false,
    reflowOverlays
}: PdfReaderWrapperProps) => {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [scriptLoadingError, setScriptLoadingError] = useState(false);

    const {
        data: jwt,
        isLoading: isJwtLoading,
        isError: isJwtError,
        error
    } = useReaderToken(pdfInfo.productId, preview);

    if (isJwtError) {
        return (
            <BasicLayout>
                <NotFound
                    scope={error?.message === 'not_on_loan_error' ? NotFoundScope.not_loaned : NotFoundScope.reader}
                />
            </BasicLayout>
        );
    }

    const ReaderWithNoSSR = dynamic(() => import('../../../page-components/reader/pdf/components/PdfReader'), {
        ssr: false
    });

    return (
        <>
            <Script
                src="/pspdfkit/pspdfkit.js"
                id="pspdfkit-js"
                onLoad={() => setScriptLoaded(true)}
                onError={() => setScriptLoadingError(true)}
            />
            {scriptLoadingError ? (
                <BasicLayout>
                    <NotFound scope={NotFoundScope.document_server} />
                </BasicLayout>
            ) : scriptLoaded && !isJwtLoading && jwt ? (
                <ReaderWithNoSSR
                    pdfInfo={pdfInfo}
                    jwt={jwt}
                    progress={progress}
                    preview={preview}
                    reflowOverlays={reflowOverlays}
                    rootPath=""
                />
            ) : null}
        </>
    );
};

export default PdfReaderWrapper;
