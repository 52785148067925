import { FEATURE_TOGGLE_CONFIG } from './featureToggleConfig';
import { useLibraryContext } from '../../context/LibraryContext';
import { isEmpty } from '../../utils/objectChecks';

type UseFeatureToggleOptions = {
    readonly siteId?: string | null | undefined;
};

export const useFeatureToggle = (feature: string) => {
    const { siteId } = useLibraryContext();
    return isFeatureEnabled(feature, { siteId });
};

export const isFeatureEnabled = (feature: string, { siteId }: UseFeatureToggleOptions = {}) => {
    const environment = process.env.NODE_ENV;
    const configurations = FEATURE_TOGGLE_CONFIG[feature];

    if (!configurations) return false;

    return configurations.some(({ site, environments }) => {
        const isSiteEnabled = isEmpty(site) || site === siteId;
        const isEnvironmentEnabled = isEmpty(environments) || environments?.includes(environment);

        return isSiteEnabled && isEnvironmentEnabled;
    });
};
