import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ApiFetchPdfkitTokenResponse, fetchPdfkitToken } from './apiFetchPdfkitToken';
import { usePatronInfo } from '../../context/AuthenticationContext';
import { useSiteParams } from '../../hooks/getters/useSiteParams';
import { postErrorMessage } from '../../pages/preview/[productId]';
import { browserClient } from '../../setup/axios';
import { isUnauthorizedError } from '../../utils/isUnauthorizedError';
import { fetchMdpUrlForPdfPToken } from '../mdp/apiFetchMdpUrl';

const QUERY_KEY = 'readerToken';
const INVALID_JWT = 'invalid JWT';

type BuildQueryKeyParams = {
    readonly siteId?: string | null;
    readonly userId: string;
    readonly productId: string;
};

const buildQueryKey = ({ siteId, userId, productId }: BuildQueryKeyParams) => {
    return [QUERY_KEY, { siteId, userId, productId }];
};

export const useReaderToken = (
    productId: string,
    preview: boolean
): UseQueryResult<ApiFetchPdfkitTokenResponse['token'], AxiosError | Error> => {
    const siteParams = useSiteParams();
    const { siteId } = siteParams;
    const { userId } = usePatronInfo();

    return useQuery({
        queryKey: buildQueryKey({ siteId, userId, productId }),
        queryFn: async () => {
            try {
                const url = await fetchMdpUrlForPdfPToken(browserClient, preview, siteParams, productId);
                const { token } = await fetchPdfkitToken(browserClient, url);

                if (!token) throw new Error(INVALID_JWT);

                return token;
            } catch (error) {
                if ((error instanceof Error && error.message === INVALID_JWT) || isUnauthorizedError(error)) {
                    if (preview) {
                        postErrorMessage();
                    } else {
                        throw new Error('not_on_loan_error');
                    }
                }
                throw error;
            }
        }
    });
};
