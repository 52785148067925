import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorModal } from './ErrorModal';
import { useFeatureToggle } from '../../hooks/getters/useFeatureToggle';
import { CLOSE_PREVIEW_MESSAGE } from '../../page-components/reader/shared/components/HeaderPreview';
import { Loader } from '../../page-components/reader/shared/components/Loader';
import { ReaderPreview } from '../../page-components/reader/shared/components/ReaderPreview';
import { PREVIEW_ERROR } from '../../pages/preview/[productId]';
import { customize } from '../../utils/localizations/customize';
import { ModalDialog } from '../ModalDialog';

const LOADED_PREVIEW_MESSAGE = 'loaded preview';

type EbookPreviewModalNewProps = {
    readonly title: string;
    readonly productId: string;
    readonly onClose: () => void;
};

export const EbookPreviewModal: FC<EbookPreviewModalNewProps> = ({ title, productId, onClose }) => {
    const { t } = useTranslation();

    const [isIframeLoading, setIsIframeLoading] = useState(true);
    const [error, setError] = useState(false);

    const isReactReaderActive = useFeatureToggle('reactReader');
    const previewBaseUrl = isReactReaderActive ? 'preview-new' : 'preview';

    useEffect(() => {
        const handler = ({ data }: { data: string }) => {
            if (data === LOADED_PREVIEW_MESSAGE) setIsIframeLoading(false);
            if (data === PREVIEW_ERROR) setError(true);
            if (data === CLOSE_PREVIEW_MESSAGE) onClose();
        };

        window.addEventListener('message', handler);

        return () => window.removeEventListener('message', handler);
    }, [onClose]);

    return (
        <>
            {error ? (
                <ErrorModal
                    title={t('preview.error_title')}
                    errorMessage={customize(t('preview.error_message'))}
                    onClose={onClose}
                    cancelLabel={t('preview.close_button')}
                />
            ) : (
                <ModalDialog className="size-wide product-preview reader-preview" isOpen={true} onClose={onClose}>
                    <>
                        {isIframeLoading ? (
                            <ReaderPreview title={title}>
                                <Loader />
                            </ReaderPreview>
                        ) : null}
                        <iframe
                            className="visual-preview-content"
                            title={t('preview.iframe_title', { productTitle: title })}
                            scrolling="no"
                            width="100%"
                            src={`/${previewBaseUrl}/${productId}`}
                            name="ebook-preview-iframe"
                            allowFullScreen={true}
                        />
                    </>
                </ModalDialog>
            )}
        </>
    );
};

export const postLoadedMessage = () => window?.top?.postMessage(LOADED_PREVIEW_MESSAGE, '*');
