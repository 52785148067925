import classNames from 'classnames';
import Link from 'next/link';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useReadingLists } from '../../api/reading-list/useReadingLists';
import { getFullTitle, ShortProduct } from '../../domain/product';
import { useAudio } from '../../hooks/utils/useAudio';
import { usePreventDefault } from '../../hooks/utils/usePreventDefault';
import { useToggle } from '../../hooks/utils/useToggle';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { pathProductDetails } from '../../utils/routes/paths';
import { ButtonWithTooltip } from '../floater/tooltip/ElementWithTooltip';
import { MenuButtonWithTooltip } from '../floater/tooltip/MenuButtonWithTooltip';
import { Icon } from '../Icon';
import { EbookPreviewModal } from '../modal/EbookPreviewModal';

type ProductTilePreviewProps = {
    readonly product: ShortProduct;
    readonly showMoreButton?: boolean;
};

export const ProductTilePreview: FC<ProductTilePreviewProps> = ({ product, showMoreButton = false }) => {
    const [overlayVisible, toggleOverlay] = useToggle(false);
    const productId = product.availability.latestAvailableIssueId ?? product.productId;

    const showPreviewButton = product.hasPreview;

    if (!showPreviewButton && !showMoreButton) return null;

    return (
        <div className="preview-overlay" data-state={overlayVisible ? 'visible' : ''}>
            <Link href={pathProductDetails(product)} legacyBehavior>
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <a className="product-link" aria-hidden="true" />
            </Link>
            {showPreviewButton ? (
                <HoverPreviewButton
                    productId={product.availability.latestAvailableIssueId ?? product.productId}
                    title={getFullTitle(product)}
                    previewUrl={product.previewUrl}
                    loanFormat={product.formatSpecifics.loanFormat}
                    setOverlayVisible={toggleOverlay}
                />
            ) : null}
            {showMoreButton ? <MoreButton productId={productId} toggleOverlay={toggleOverlay} /> : null}
        </div>
    );
};

type HoverPreviewButtonProps = {
    readonly loanFormat: LoanFormatType;
    readonly productId: string;
    readonly previewUrl?: string;
    readonly title: string;
    readonly setOverlayVisible: (visible: boolean) => void;
};

const HoverPreviewButton: FC<HoverPreviewButtonProps> = ({
    loanFormat,
    productId,
    title,
    previewUrl,
    setOverlayVisible
}) => {
    switch (loanFormat) {
        case LoanFormatType.eaudiobooks:
            return (
                <EAudiobookHoverPreviewButton
                    title={title}
                    previewUrl={previewUrl}
                    setOverlayVisible={setOverlayVisible}
                />
            );
        case LoanFormatType.ebooks:
        case LoanFormatType.eMagazines:
        case LoanFormatType.eMagazineIssues:
            return <EBookHoverPreviewButton title={title} productId={productId} />;
        default:
            return null;
    }
};

type EAudiobookHoverPreviewButtonProps = {
    readonly title: string;
    readonly previewUrl?: string;
    readonly setOverlayVisible: (visible: boolean) => void;
};

const EAudiobookHoverPreviewButton: FC<EAudiobookHoverPreviewButtonProps> = ({
    title,
    previewUrl,
    setOverlayVisible
}) => {
    const { t } = useTranslation();

    const { playing, toggle, progress, error } = useAudio({
        src: previewUrl
    });

    useEffect(() => setOverlayVisible(playing), [playing, setOverlayVisible]);

    const progressStyles = useMemo(
        () => ({
            strokeDashoffset: `${100 - progress * 75}px`
        }),
        [progress]
    );

    const tooltip = error
        ? t('defaults.button.preview.eaudiobook.error', { title })
        : playing
          ? t('defaults.button.preview.eaudiobook.pause')
          : t('defaults.button.preview.eaudiobook.play');

    return (
        <>
            <ButtonWithTooltip
                tooltip={tooltip}
                className={classNames('audio-preview libcolor-bg-hover', {
                    'is-playing': playing,
                    'in-progress': progress > 0,
                    error
                })}
                role={error ? 'alert' : undefined}
                aria-label={error ? t('defaults.button.preview.eaudiobook.error', { title }) : undefined}
                onClick={() => {
                    if (!error) toggle();
                }}
                hoverSafePolygon={false}
            >
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" className="audio-progress">
                    <circle r="12px" transform="rotate(-90) translate(-13 13)" style={progressStyles} />
                </svg>
                {playing ? (
                    <div className="pause">
                        <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 108 180"
                            aria-label={t('defaults.button.preview.eaudiobook.pause')}
                        >
                            <g transform="translate(-61.875 -198.56)">
                                <path d="m169.88 378.56h-36v-180h36zm-72 0h-36v-180h36z" />
                            </g>
                        </svg>
                    </div>
                ) : (
                    <div className="play">
                        <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 115.17 121.5"
                            aria-label={t('defaults.button.preview.eaudiobook.play')}
                        >
                            <g transform="translate(-39.234 -217.12)">
                                <path d="m154.41 277.88-115.17 60.75v-121.5z" />
                            </g>
                        </svg>
                    </div>
                )}
            </ButtonWithTooltip>
        </>
    );
};

type EBookHoverPreviewButtonProps = {
    readonly title: string;
    readonly productId: string;
};

const EBookHoverPreviewButton: FC<EBookHoverPreviewButtonProps> = ({ title, productId }) => {
    const { t } = useTranslation();

    const [showEbookPreview, setShowEbookPreview] = useState(false);

    const handleOnClose = useCallback(() => setShowEbookPreview(false), []);

    return (
        <>
            <ButtonWithTooltip
                tooltip={t('defaults.button.preview.ebook')}
                className="preview libcolor-bg-hover"
                onClick={() => {
                    setShowEbookPreview(true);
                }}
                hoverSafePolygon={false}
            >
                <Icon icon="preview-ebook-outline" label={t('defaults.button.preview.ebook')} />
            </ButtonWithTooltip>
            {showEbookPreview ? (
                <EbookPreviewModal title={title} productId={productId} onClose={handleOnClose} />
            ) : null}
        </>
    );
};

type MoreButtonProps = {
    productId: string;
    toggleOverlay: () => void;
};

const MoreButton: FC<MoreButtonProps> = ({ productId, toggleOverlay }) => {
    const { t } = useTranslation();

    const [menuVisible, toggleMenuVisible] = useToggle(false);

    const { isOnReadingLists, addToReadingLists, removeFromReadingLists } = useReadingLists();

    const toggle = useCallback(() => {
        toggleOverlay();
        toggleMenuVisible();
    }, [toggleMenuVisible, toggleOverlay]);

    const onClick = useCallback(() => {
        if (isOnReadingLists(productId)) {
            removeFromReadingLists(productId);
        } else {
            addToReadingLists(productId);
        }
    }, [productId, isOnReadingLists, removeFromReadingLists, addToReadingLists]);

    const menuContent = (
        <div className="component popover-list">
            <ul>
                <li>
                    <a href="#" onClick={usePreventDefault(onClick)} role="button">
                        {isOnReadingLists(productId)
                            ? t('defaults.button.more.remove_from_list')
                            : t('defaults.button.more.add_to_list')}
                    </a>
                </li>
            </ul>
        </div>
    );

    return (
        <MenuButtonWithTooltip
            tooltip={t('defaults.button.more.tooltip')}
            menu={menuContent}
            className="more-actions libcolor-bg-hover"
            aria-expanded={menuVisible}
            onClick={toggle}
            hoverSafePolygon={false}
        >
            <Icon icon="more-horizontal" label={t('defaults.button.more.tooltip')} />
        </MenuButtonWithTooltip>
    );
};
