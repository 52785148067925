type Environments = 'development' | 'production' | 'test';

type FeatureToggleConfigItem =
    | {
          readonly site?: string;
          readonly environments: ReadonlyArray<Environments>;
      }
    | {
          readonly site: string;
          readonly environments?: ReadonlyArray<Environments>;
      };

type FeatureToggleConfig = Record<string, ReadonlyArray<FeatureToggleConfigItem>>;

// https://borrowbox.atlassian.net/browse/PP-3637
const IRELAND_SITES = [
    '3868',
    '4810',
    '4811',
    '4812',
    '4813',
    '4814',
    '4815',
    '4816',
    '4817',
    '4818',
    '4819',
    '4821',
    '4822',
    '4823',
    '4824',
    '4825',
    '4826',
    '4827',
    '4828',
    '4829',
    '4830',
    '4831',
    '4832',
    '4833',
    '4834',
    '4835',
    '4836',
    '4837',
    '4838',
    '4839'
].map(site => ({ site }));

/**
 * Features are enabled if:
 * - configurations for given feature name exist AND
 * - in one of these configurations
 *   - `site` is empty OR `site` includes given/context siteId AND
 *   - `environments` are empty OR `environments` is current environment
 *
 * @example
 * newFeature: [
 *     {
 *         site: '1803', // YSL: ysl.borrowbox.io
 *         environments: ['development']
 *     }
 * ]
 */
export const FEATURE_TOGGLE_CONFIG: FeatureToggleConfig = {
    epressReflow: [
        {
            site: '1803' // YSL: ysl.borrowbox.io
        },
        {
            site: '6044' // E2E / Branch Deployments: e2e-website.borrowbox.io
        },
        {
            site: '6541', // Solution Mgmt Tests: solman.borrowbox.com
            environments: ['production']
        },
        {
            site: '5337', // Ireland Demo Site: iedemo.borrowbox.com
            environments: ['production']
        },
        {
            site: '6765', // Deutschland Demo Site: dedemo.borrowbox.com
            environments: ['production']
        },
        ...IRELAND_SITES
    ],
    epressColumns: [
        {
            site: 'none' // currently disabled
        }
    ],
    reactReader: [
        {
            site: '1803' // YSL: ysl.borrowbox.io, ysl.borrowbox.com
        },
        {
            site: '6541' // Solution Mgmt Tests: solman.borrowbox.com
        }
    ],
    readerEdgeCaseOverview: [
        {
            site: '1803', // YSL: ysl.borrowbox.io
            environments: ['development']
        }
    ],
    searchResultSidebar: [
        {
            site: 'none' // currently disabled
        }
    ],
    measureReaderLoadingPerformance: [
        {
            site: '1803' // YSL: ysl.borrowbox.io
        }
    ]
};
