import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StatusMessage } from '../components/StatusMessage';
import { customize } from '../utils/localizations/customize';

export enum NotFoundScope {
    product = 'product',
    reader = 'reader',
    document_server = 'document_server',
    not_loaned = 'not_loaned'
}

type NotFoundProps = {
    readonly scope?: NotFoundScope;
    readonly productId?: string;
};

export const NotFound: FC<NotFoundProps> = ({ scope, productId }) => {
    const { t } = useTranslation();

    return (
        <StatusMessage
            title={t('common.not_found.title', { context: scope })}
            subtitle={customize(t('common.not_found.subtitle', { productId, context: scope }))}
        />
    );
};
