import { FC, ReactNode } from 'react';

type ToolbarProps = {
    readonly progressBar: ReactNode;
    readonly secondaryRowLeft?: ReactNode;
    readonly secondaryRowCenter?: ReactNode;
    readonly secondaryRowRight?: ReactNode;
    readonly primaryRowLeft?: ReactNode;
    readonly primaryRowCenter?: ReactNode;
    readonly primaryRowRight?: ReactNode;
};

export const Toolbar: FC<ToolbarProps> = ({
    progressBar,
    primaryRowLeft,
    primaryRowCenter,
    primaryRowRight,
    secondaryRowLeft,
    secondaryRowCenter,
    secondaryRowRight
}) => {
    return (
        <>
            {progressBar}

            <div className="row-wrapper">
                <div className="row row-one">
                    <div className="left">{secondaryRowLeft}</div>
                    <div className="center">{secondaryRowCenter}</div>
                    <div className="right">{secondaryRowRight}</div>
                </div>
                <div className="row row-two">
                    <div className="left">{primaryRowLeft}</div>
                    <div className="center">{primaryRowCenter}</div>
                    <div className="right">{primaryRowRight}</div>
                </div>
            </div>
        </>
    );
};
