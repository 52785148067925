import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const Loader: FC = () => {
    const { t } = useTranslation();
    return (
        <div className="loader-wrapper">
            <div className="loader libcolor-loader">{t('reader.common.loading')}</div>
        </div>
    );
};
