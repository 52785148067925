import Link from 'next/link';
import { FC, Fragment, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { useProductTileContext } from './ProductTileContent';
import { useLoanStatus } from '../../context/LoanStatusContext';
import { Series } from '../../page-components/series/Series';
import { LoanFormatType } from '../../utils/domain/loanFormat';
import { pathPerformer, pathProductDetails } from '../../utils/routes/paths';

type ProductTileInformationProps = PropsWithChildren<{
    readonly titleId: string;
}>;

export const ProductTileInformation: FC<ProductTileInformationProps> = ({ children, titleId }) => {
    return (
        <div className="information">
            <ProductTileInformationSeries />
            <ProductTileInformationTitle titleId={titleId} />
            <ProductTileInformationAuthors />
            {children}
            <ProductTileInformationMagazineIssueStatus />
        </div>
    );
};

export const ProductTileInformationLoanHistory: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="details">
            <div className="information">
                {children}
                <ProductTileInformationSeries />
                <ProductTileInformationTitle />
                <ProductTileInformationAuthors />
                <ProductTileInformationMagazineIssueStatus />
            </div>
        </div>
    );
};

export const ProductTileAction: FC<PropsWithChildren> = ({ children }) => {
    return <div className="actions">{children}</div>;
};

type ProductTileInformationTitleProps = {
    readonly titleId?: string;
};

const ProductTileInformationTitle: FC<ProductTileInformationTitleProps> = ({ titleId }) => {
    const product = useProductTileContext();
    if (!product) return null;

    const { title } = product;

    return (
        <div className="title" title={title} id={titleId} lang="">
            <Link href={pathProductDetails(product)}>{title}</Link>
        </div>
    );
};

const ProductTileInformationSeries = () => {
    const product = useProductTileContext();
    if (!product) return null;

    const { seriesNumber, seriesName, seriesId } = product;

    if (!seriesName || !seriesId) return null;

    return (
        <div className="series">
            <Series id={seriesId} name={seriesName} number={seriesNumber} />
        </div>
    );
};

const ProductTileInformationMagazineIssueStatus = () => {
    const product = useProductTileContext();
    const { t } = useTranslation();

    const loanStatus = useLoanStatus({ productId: product?.productId ?? '' });

    if (!product) return null;

    const {
        formatSpecifics: { loanFormat }
    } = product;

    const isMagazineIssue = loanFormat === LoanFormatType.eMagazineIssues;
    const isRead = loanStatus?.inLoanHistory && !loanStatus?.active;

    if (!isMagazineIssue || !isRead) return null;

    return <div className="magazine-issue-status theme-neutral theme-bold">{t('home.myloans.badges.read')}</div>;
};

const ProductTileInformationAuthors = () => {
    const product = useProductTileContext();

    if (!product?.authors) return null;

    return (
        <div className="author">
            {product.authors.map((author, idx) => {
                const isLast = idx === product.authors!.length - 1;
                return (
                    <Fragment key={idx}>
                        <Link href={pathPerformer(author.id, author.name)} title={author.name}>
                            {author.name}
                        </Link>
                        {isLast ? null : ', '}
                    </Fragment>
                );
            })}
        </div>
    );
};
