export enum AudioFileFormat {
    mp3 = 'MP3'
}

export enum TextFileFormat {
    epub = 'ePub',
    pdf = 'PDF'
}

export type FileFormat = AudioFileFormat | TextFileFormat;
