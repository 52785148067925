import { ComponentProps, FC, PropsWithChildren, ReactNode } from 'react';

import { Wrapper } from './components/Wrapper';
import { useTooltipFloater } from './hooks/useTooltipFloater';
import { useToggle } from '../../../hooks/utils/useToggle';
import { Wrapper as PopoverWrapper } from '../popover/components/Wrapper';

type TooltipMenuButtonProps = {
    readonly tooltip: ReactNode;
    readonly menu: ReactNode;
    readonly onClick?: () => void;
    readonly hoverSafePolygon?: boolean;
} & ComponentProps<'button'>;

export const MenuButtonWithTooltip: FC<PropsWithChildren<TooltipMenuButtonProps>> = ({
    tooltip,
    menu,
    onClick,
    hoverSafePolygon,
    children,
    ...props
}) => {
    const [menuOpen, toggleMenuOpen] = useToggle(false);
    const [onClickToggled, toggleOnClickToggled] = useToggle(false);

    const handleOpenChange = (open: boolean) => {
        // on Floater UI close event via i.e. mouse leave or escape key
        if (!open) {
            toggleMenuOpen(false); // reset menu state
            if (onClickToggled) triggerOnClick(); // toggle `onClick` handler back if it was toggled before
        }
    };

    const { open, setOpen, arrowRef, reference, floatingProps, getReferenceProps, getFloatingProps } =
        useTooltipFloater({
            onOpenChange: handleOpenChange,
            hoverSafePolygon,
            hoverEnabled: !menuOpen
        });

    const triggerOnClick = () => {
        onClick?.();
        toggleOnClickToggled(); // monitor if `onClick` toggle function was toggled
    };

    const handleClick = () => {
        triggerOnClick();
        if (!menuOpen) setOpen(true);
        toggleMenuOpen();
    };

    return (
        <>
            <button ref={reference} {...getReferenceProps()} type="button" onClick={handleClick} {...props}>
                {children}
            </button>
            {menuOpen ? (
                <PopoverWrapper open={open} floatingProps={floatingProps} getFloatingProps={getFloatingProps}>
                    {menu}
                </PopoverWrapper>
            ) : (
                <Wrapper
                    open={open}
                    arrowRef={arrowRef}
                    floatingProps={floatingProps}
                    getFloatingProps={getFloatingProps}
                >
                    {tooltip}
                </Wrapper>
            )}
        </>
    );
};
