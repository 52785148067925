import { useTranslation } from 'react-i18next';

import { postCloseMessage } from './HeaderPreview';

export const ButtonClosePreview = () => {
    const { t } = useTranslation();

    return (
        <span className="visible-md">
            <button className="button-neutral size-standalone" onClick={() => postCloseMessage()}>
                <span className="icon cross"></span>
                {t('preview.modal_close_button')}
            </button>
        </span>
    );
};
