import { FC, ReactNode } from 'react';

import { ModalDialog } from '../ModalDialog';

type ErrorModalProps = {
    readonly title: string;
    readonly errorMessage: string | ReactNode;
    readonly onClose: () => void;
    readonly cancelLabel: string;
};

export const ErrorModal: FC<ErrorModalProps> = ({ title, errorMessage, onClose, cancelLabel }) => {
    return (
        <ModalDialog
            title={title}
            errorMessage={errorMessage}
            isOpen={true}
            buttons={
                <button className="button-neutral" onClick={onClose}>
                    {cancelLabel}
                </button>
            }
            totalNumberButtons={1}
            onClose={onClose}
        />
    );
};
