import dynamic from 'next/dynamic';

import { EPubInfo } from '../../../page-components/reader/epub/components/EpubReader';
import { EpubReaderContextProviderProps } from '../../../page-components/reader/epub/components/EpubReaderContextProvider';
import { Manifest } from '../../../page-components/reader/epub/lib/types';
import { ReaderProgress } from '../../../page-components/reader/shared/types/progress';

type EPubReaderWrapperProps = {
    readonly productInfo: EPubInfo;
    readonly progress?: ReaderProgress;
    readonly preview?: boolean;
    readonly manifest: Manifest;
};

const EPubReaderWrapper = ({ productInfo, progress, preview = false, manifest }: EPubReaderWrapperProps) => {
    const EpubReaderWithNoSSR = dynamic<{}>(
        () => import('../../../page-components/reader/epub/components/EpubReader').then(module => module.EpubReader),
        {
            ssr: false
        }
    );

    const EpubReaderContextProviderWithNoSSR = dynamic<EpubReaderContextProviderProps>(
        () =>
            import('../../../page-components/reader/epub/components/EpubReaderContextProvider').then(
                module => module.EpubReaderContextProvider
            ),
        {
            ssr: false
        }
    );

    return (
        <EpubReaderContextProviderWithNoSSR
            productId={productInfo.productId}
            title={productInfo.title}
            authors={productInfo.authors}
            coverUrl={productInfo.coverUrl}
            initialProgress={progress}
            preview={preview}
            manifest={manifest}
        >
            <EpubReaderWithNoSSR />
        </EpubReaderContextProviderWithNoSSR>
    );
};

export default EPubReaderWrapper;
