import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { customize } from '../../utils/localizations/customize';
import { isNone } from '../../utils/objectChecks';
import { pathSeries } from '../../utils/routes/paths';

interface SeriesProps {
    id: string | null;
    name: string | null;
    number?: number | null;
    longDash?: boolean;
}

export const Series = ({ id, name, number, longDash }: SeriesProps) => {
    const { t } = useTranslation();

    if (!name || !id) return null;

    const options = { name, number, dash: longDash ? '—' : '–' };

    return (
        <Link href={pathSeries(id, name)} legacyBehavior>
            <a className="font-italic">
                {isNone(number)
                    ? t('product_details.book_series', options)
                    : customize(t('product_details.book_series_with_number', options))}
            </a>
        </Link>
    );
};
